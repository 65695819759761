/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./Hero.css";
import Header from "../Header/Header";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";

import {motion} from "framer-motion";
const Hero = () => {
    const transition = {type: "spring", duration: 3};
    return (
        <div className="Hero">
            <div className="blur Hero-blur"></div>
            <div className="left-h">
                <Header />
                {/* the best adding */}
                <div className="the-add">
                    <motion.div
                        className="the-add-div"
                        initial={{left: "238px"}}
                        whileInView={{left: "9px"}}
                        transition={transition}
                    ></motion.div>

                    <span>THE BEST FITNESS CLUB IN THE TOWN</span>
                </div>
                {/* Hero texting */}
                <div className="hero-text">
                    <div>
                        <span className="stroke-text">shape </span>
                        <span>your</span>
                    </div>
                    <div>
                        <span>ideal body</span>
                    </div>
                    <div>
                        <span>
                            ideal body in here we will hel you to shape and build your idealbody and live up your life
                            to fullest
                        </span>
                    </div>
                </div>
                {/* figures */}
                <div className="figures">
                    <div>
                        <span>+140</span>
                        <span>expert coaches</span>
                    </div>
                    <div>
                        <span>+978</span>
                        <span>members joined</span>
                    </div>
                    <div>
                        <span>+50</span>
                        <span>fitness programs</span>
                    </div>
                </div>
                {/* Hero-buttton  */}
                <div className="hero-buttons">
                    <button className="btn"> Get Started</button>
                    <button className="btn"> Learn More</button>
                </div>
            </div>
            {/* website right side */}
            <div className="right-h">
                <button className="btn"> Join Now</button>
                <motion.div
                    initial={{right: "-1rem"}}
                    whileInView={{right: "4rem"}}
                    transition={transition}
                    className="heart-rate"
                >
                    <img src={Heart} alt="Heart" />
                    <span>Heart Rate</span>
                    <span>116 bpm</span>
                </motion.div>
                {/* hero-image */}
                <img src={hero_image} aly="hero_image" className="hero-image" />
                <motion.img
                    initial={{right: "9rem"}}
                    whileInView={{right: "20rem"}}
                    transition={transition}
                    src={hero_image_back}
                    aly="hero-image-back"
                    className="hero-image-back"
                />
                {/* Calories */}

                <motion.div
                    initial={{right: "37rem"}}
                    whileInView={{right: "28rem"}}
                    transition={transition}
                    className="Calories"
                >
                    <img src={Calories} alt="Calories" />
                    <div>
                        <span>Calories Burend</span>
                        <span>220 kcal</span>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default Hero;

 import './App.css';
import Footer from './components/Footer/Footer.jsx';
import Hero from './components/Hero/Hero';
import Join from './components/Join/Join.jsx';
import Plan from './components/Plan/Plan.jsx';
import Program from './components/Program/Program.jsx'
import Reasons from './components/Reasons/Reasons.jsx';
import Testimonaials from './components/Testimonaials/Testimonaials.jsx';
function App() {
  return (
    <div className="App">
          <Hero/>
          <Program/>
          <Reasons/>
          <Plan/>
          <Testimonaials/>
          <Join/>
          <Footer/>

    </div>
  );
}

export default App;

import React from 'react'
import "./Program.css"
import {programsData} from '../../data/programsData'
import RightArrow from '../../assets/rightArrow.png'
const program = () => {
  return (
      <div className="program" id="program">
         {/* Header */}
         <div className="program-header">
            <span className='stroke-text'>Explore our</span>
            <span>Programs</span>
            <span className='stroke-text'>to Shape you</span>
         </div>
          <div className="program-categories">
            {programsData.map((program)=>(
              <div className="category">
                {program.image}
                <span>{program.heading}</span>
                <span>{program.details}</span>
                <div className="join-now">
                  <span>JoinNow</span>
                  <img src={RightArrow} alt="" />
                </div>
              </div>
          ))}
          </div>
      </div>
    
  )
}

export default program

import React from "react";
import {plansData} from "../../data/plansData";
import "./Plan.css";
import whiteTick from "../../assets/whiteTick.png"; // Correct import statement

const Plan = () => {
    return (
        <div className="plan">
            <div className="blur plan-blur-1"></div>
            <div className="blur plan-blur-2"></div>
            <div className="program-header" style={{gap: "2rem"}}>
                <span className="stroke-text">READY TO START</span>
                <span>YOUR JOURNEY</span>
                <span className="stroke-text">NOW WITH US</span>
            </div>

            {/* plan cards */}
            <div className="plans">
                {plansData.map((plan, i) => (
                    <div className="plan-card" key={i}>
                        {plan.icon}
                        <span>{plan.name}</span>
                        <span>{plan.price}</span>
                        <div className="features">
                            {plan.features.map((feature, j) => (
                                <div className="feature" key={j}>
                                    {" "}
                                    {/* Correct key usage */}
                                    <img src={whiteTick} alt="tick" />
                                    <span>{feature}</span>
                                </div>
                            ))}
                        </div>
                        <div>
                            <span>See more benefits</span>
                        </div>
                        <button className="btn">Join Now</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Plan;
